import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../layouts"
import Seo from "../components/seo"
import {
  BreadCrumb,
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  MarkdownContent,
} from "../components/Section"
import ArticleCard from "../components/ArticleCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import Badge from "../components/Badge"
import BreakpointDown from "../components/Media/BreakpointDown"

const SectionBefore = styled(Section)`
  &:before {
    ${BreakpointDown.md`
		height: 66%;
	`}
  }
  ${BreakpointDown.md`
	.badge-sm{ display:block; text-align:center;}
	`}
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: center;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
    `}
`
const MainPanel = styled.div`
  margin: 0;
`
const Markdownbody = styled.div`
  .gatsby-image-wrapper {
    border-radius: 6px;
    box-shadow: 0 20px 40px rgba(3, 51, 102, 0.15);
    margin-bottom: 30px;
    ${BreakpointUp.lg` 
	   		margin-bottom:60px;
	   `}
  }
  h2 {
    font-size: 24px;
    line-height: 30px;
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media (min-width: 1600px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
  h3 {
    font-size: 18px;
    line-height: 26px;
    @media (min-width: 992px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media (min-width: 1600px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 1600px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  ul {
    font-size: 16px;
    line-height: 26px;
  }
`
const MarkdownHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${BreakpointDown.md`
        flex-direction: column;
        margin-top:10px;
    `}
  margin:0 -10px 40px -10px;
  h1,
  span {
    padding: 0 10px;
    margin-bottom: 0;
  }
  h1 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  span {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    color: #666;
    font-size: 12px;
  }
`

const BlogTemplate = ({ location, data }) => {
  const pageData = data.contentfulBlog
  const relatedBlogData = data.allContentfulBlog.edges.filter(
    item => item.node.category.name === pageData.category.name
  )
  const blogImage = getImage(pageData.image)
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <BreadCrumb textAlign="left">
        <Container maxWidth="1200px" pl="3%" pr="3%">
          <Link to="/">Home </Link>
          <Link to="/blog">Blog </Link>
          <span>{pageData.title}</span>
        </Container>
      </BreadCrumb>
      <SectionBefore
        xpt="190px"
        mpt="170px"
        pt="110px"
        xpb="100px"
        mpb="60px"
        pb="30px"
        bgColor="#fff"
        bgBeforeWidth="100%"
        bgBeforeHeight="100%"
        topBefore="0"
        bgBefore="linear-gradient(to bottom, #f0f8ff 0%, #f0f8ff 20%,#ffffff 20%,#ffffff 100%)"
      >
        <Container maxWidth="1200px" pl="3%" pr="3%">
          <MainPanel>
            <MarkdownContent>
              <span className="badge-sm">
                <Badge text={pageData.category.name} />
              </span>
              <MarkdownHeader>
                <h1>{pageData.title}</h1>
                <span>{pageData.publishDate}</span>
              </MarkdownHeader>
              <Markdownbody>
                <GatsbyImage image={blogImage} alt={pageData.title} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </Markdownbody>
            </MarkdownContent>
          </MainPanel>
        </Container>
      </SectionBefore>
      <Section
        xpt="90px"
        mpt="60px"
        pt="30px"
        xpb="60px"
        mpb="30px"
        pb="0"
        bgColor="#F0F8FF"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle mb="10px">You Should Know Better</SectionTitle>
          <SectionDescription maxWidth="1190px" mb="40px">
            <p>
              At Liberty Metal Structures, we believe that we are building more
              than a structure - we are building a relationship! We want our
              customers to be well informed about the benefits of high-quality
              steel, eco-friendly construction, and the customizable options
              that make metal buildings the best choice for you. The expert
              articles provided here are for your information and enjoyment; we
              know that, soon, you will love metal buildings as much as we do!
            </p>
          </SectionDescription>
          <SectionGrid>
            {relatedBlogData.map((item, i) => {
              if (i < 3) {
                return (
                  <SectionGridCol key={i}>
                    <ArticleCard data={item.node} />
                  </SectionGridCol>
                )
              }
              return <></>
            })}
          </SectionGrid>
        </Container>
      </Section>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    contentfulBlog(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      url
      category {
        name
      }
      publishDate(fromNow: true)
      description {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlog {
      edges {
        node {
          title
          url
          category {
            name
          }
          publishDate(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
